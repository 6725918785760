import React from 'react';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { graphql } from 'gatsby';

import { wrapper, layout } from './faq.module.scss';
import { IBanner } from '../models/banner.model';
import { ISitePageContext } from '../models/site-page.model';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';

import SingleFaq, { ISingleFaq } from '../components/molecules/single-faq';
import SEO from '../components/seo';
import MainLayout from '../layouts/main-layout';

interface IFaqProps {
    readonly data: {
        bannersCollection: {
            banners: IBanner[];
        } | null;
        allFaq: { edges: { node: ISingleFaq }[] };
    };
    readonly pageContext: ISitePageContext;
}

const Faq: React.FC<IFaqProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { bannersCollection, allFaq } = data;

    const banner = bannersCollection?.banners[0];

    return (
        <>
            <SEO title={t('faq.title')} />
            <MainLayout
                className={layout}
                bannersData={banner && { banner, titleData: { Tag: 'h1' } }}
                breadcrumbsConfig={{
                    items: [getBreadcrumbItem(pageContext)],
                }}
            >
                <div className={wrapper}>
                    {allFaq.edges.map(({ node }, index) => {
                        return <SingleFaq key={`single-faq-${index}`} data={node} />;
                    })}
                </div>
            </MainLayout>
        </>
    );
};

export const query = graphql`
    query($language: String!, $placeId: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        bannersCollection(placeId: { eq: $placeId }) {
            ...bannersCollectionFields
        }

        allFaq {
            edges {
                node {
                    ...faqFields
                }
            }
        }
    }
`;

export default Faq;
