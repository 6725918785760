import React from 'react';

import { button, arrow, active } from './button-faq.module.scss';

import Arrow from '../../assets/images/svg/arrow.svg';

interface IButtonFaqProps {
    className?: string;
    isOpen?: boolean;
    onClick?(): void;
}

const ButtonFaq: React.FC<IButtonFaqProps> = ({ onClick, isOpen = true, className }) => {
    const handleClick = () => {
        if (typeof onClick !== 'function') return;
        onClick();
    };
    return (
        <button className={`${button} ${className}`} onClick={handleClick}>
            <div className={isOpen ? `${arrow} ${active}` : arrow}>
                <Arrow />
            </div>
        </button>
    );
};

export default ButtonFaq;
