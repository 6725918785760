import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { faq, questions, answers, button } from './single-faq.module.scss';
import { config } from '../../config';
import { useTranslation } from '../../hooks/use-translation';

import ButtonFaq from '../atoms/button-faq';
import Markdown from '../hoc/markdown';

const { translationKeys } = config;

export interface ISingleFaq {
    translations: {
        [key: string]: {
            question?: string;
            answer?: string;
        };
    };
}

interface ISingleFaqProps {
    data: ISingleFaq;
}

const SingleFaq: React.FC<ISingleFaqProps> = ({ data }) => {
    const { question, answer } = useTranslation(data, translationKeys.singleFaq);
    const [open, setOpen] = useState(false);

    const handleClick = () => setOpen(!open);

    return (
        <div className={faq} onClick={handleClick}>
            <div className={questions}>{question}</div>
            <AnimateHeight className={answers} duration={500} height={open ? 'auto' : 0}>
                <Markdown>{answer}</Markdown>
            </AnimateHeight>
            <ButtonFaq className={button} onClick={handleClick} isOpen={open} />
        </div>
    );
};

export default SingleFaq;
